import React from 'react'

import { Disclosure } from '@headlessui/react'
import DisclosurePanel from './Mobile/MobileHeader';
import MobileMenuButtons from './Mobile/MobileMenuButtons';
import UserNavigation from './Desktop/UserNavigation/UserNavigation'
import HeaderIcon from './HeaderIcon'
import Navigation from './Desktop/Navigation';
import ProfilModale from '../Profil/ProfilModale';
import ComptesModale from '../Comptes/ComptesModale';
import DevicesModal from '../Devices/DevicesModale';

function HeaderContainer({ navigation, userNavigation, user, isProfilModaleOpen, isComptesModaleOpen, isDevicesModaleOpen, onCloseProfilModale, onCloseComptesModale, onCloseDevicesModale }: Header.ContainerProps) {

  return <Disclosure as="nav" className="bg-primary-800 shadow-lg">
    {({ open }) => (
      <>
        <div className="w-full mx-auto px-2 lg:pr-4 ">
          <div className="flex items-center justify-between h-14">
            <div className="flex items-center w-full">
              <HeaderIcon />
              <Navigation navigation={navigation} />
              <UserNavigation userNavigation={userNavigation} user={user.user} />
            </div>
            <div className="hidden lg:block">
              <div className="ml-4 flex items-center lg:ml-0">
                {/* <Notifications /> à Réactiver en temps voulu*/}
                {/* <UserNavigation userNavigation={userNavigation} user={user.user} /> */}
              </div>
            </div>
            <div className='flex items-center lg:hidden'>
              {/*<div className='mr-2'><Search /></div> à reactiver*/}
              <MobileMenuButtons open={open} />
            </div>

          </div>
        </div>

        <DisclosurePanel navigation={navigation} userNavigation={userNavigation} user={user} />
        <ProfilModale isOpen={isProfilModaleOpen} onClose={() => onCloseProfilModale && onCloseProfilModale()} />
        <ComptesModale isOpen={isComptesModaleOpen} onClose={() => onCloseComptesModale && onCloseComptesModale()} />
        <DevicesModal isOpen={isDevicesModaleOpen} onClose={() => onCloseDevicesModale && onCloseDevicesModale()} />
      </>
    )}
  </Disclosure>
}

export default HeaderContainer