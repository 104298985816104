import React from 'react';
import DeviceSessionType from '../../../types/DeviceSession';
import PairingListItem from './PairingListItem';
import Spinner from '../../../Components/Spinner';

interface PairingListType {
  deviceSessions: DeviceSessionType[],
  deleteSession: (id: string) => void,
  loading: boolean
}

function PairingList({deviceSessions = [], deleteSession, loading}: PairingListType) {
  return (
    <div className='h-full max-h-full divide-y'>
      {
        loading && (
          <div className='w-full h-full flex items-center justify-center italic'><Spinner /></div>
        )
      }
      {
        !loading && deviceSessions?.length === 0 && <div className='w-full h-full flex items-center justify-center italic'>Aucune session active</div>
      }
      {deviceSessions?.map((session: any) => {
            return <PairingListItem key={session.id} deviceSession={session} deleteSession={() => deleteSession(session.id)} />
      })}
    </div>
  )
}

export default PairingList