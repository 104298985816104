import clsx from 'clsx'
import React from 'react'

type PairingFormAppItemType = {
  label: string,
  selected: boolean,
  onSelect: () => void
}

function PairingFormAppItem ({
  label,
  selected,
  onSelect
}: PairingFormAppItemType) {

  return (
    <div className={clsx('relative py-3 px-4 flex items-center hover:bg-gray-50 ', selected && 'bg-stone-100 shadow-inner  first:sticky first:top-0 first:z-10 relative border-l-0 hover:bg-stone-100')}>
      <div 
        className={clsx(
        '',
        selected ? 'before:absolute before:-left-1.5 before:top-0 before:rounded-full before:bg-secondary-500 before:h-full before:w-1 relative -m-1 flex-1 block p-1 font-semibold' : 'relative -m-1 flex-1 block p-1'
        ) 
        } 
        onClick={onSelect}
        >
        {label}
      </div>
    </div>
  )

}

export default PairingFormAppItem