import React, { useEffect } from 'react';
import Button from '../../Components/Button';
import Modal from '../../Components/Modal/Modal';
import { useP } from '../../services/i18n';
import DeviceModaleBody from './DeviceModaleBody';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DELETE_DEVICE_SESSION, GET_DEVICE_SESSIONS } from './queries';
import toast from 'react-hot-toast';


type ProfilModaleType = {
  isOpen?: boolean,
  onClose: () => void
}

function DevicesModal({ isOpen = false, onClose }: ProfilModaleType) {

  const p = useP();

  const [deleteDeviceSession] = useMutation(DELETE_DEVICE_SESSION);

  const [getDeviceSessions, {data: deviceSessionsData, loading: deviceSessionsLoading}] = useLazyQuery(GET_DEVICE_SESSIONS, {
    fetchPolicy: 'network-only'
  });

  const reloadDeviceSessions = () => {
    getDeviceSessions();
  }

  
  const deleteSession = (id: string) => {
    deleteDeviceSession({
      variables: {
        id
      }
    })
    .then(() => {
      toast.success('Session supprimée avec succès');
      reloadDeviceSessions();
    })
    .catch(() => {
      toast.error('Erreur lors de la suppression de la session');
    })
  }

  useEffect(() => {
    if (isOpen) {
      getDeviceSessions();
    }
  }, [isOpen])

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        content={{
          title: <>
            <div className='flex items-center'>
              <div>{p.t('userNavigation.devices.label')}</div>
            </div>
          </>
          ,
          body: <DeviceModaleBody deviceSessions={deviceSessionsData?.deviceSessions} reloadDeviceSessions={reloadDeviceSessions} loading={deviceSessionsLoading} deleteSession={deleteSession}/>,
          footer: <><div className='flex items-center justify-end'>
            <Button onClick={onClose}>
              {p.t('actions.fermer')}
            </Button>
          </div></>,
        }}
        className="w-[70rem]"
      />
    </>
  )
}

export default DevicesModal