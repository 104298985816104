import { gql } from "@apollo/client";

const GET_DEVICE_SESSIONS = gql`
  query GetDeviceSession {
    deviceSessions {
      id
      nom
      deviceSessionView {
        libelle
      }
      accessToken {
        userAgent
      }
    }
  }
`

const PAIR_DEVICE = gql`
  mutation PairDevice($nomSession: String, $pairingCode: String, $deviceSessionViewCode: String) {
    pairDevice(nomSession: $nomSession, pairingCode: $pairingCode, deviceSessionViewCode: $deviceSessionViewCode) {
      error
      success
    }
  }
`

const DELETE_DEVICE_SESSION = gql`
  mutation DeleteDeviceSession($id: String) {
    deleteDeviceSession(id: $id) {
      message
      success
    }
  }
`

export {
  PAIR_DEVICE,
  GET_DEVICE_SESSIONS,
  DELETE_DEVICE_SESSION
}