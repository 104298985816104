import React, { useState } from 'react'
import PairingForm from './PairingForm/PairingForm'
import PairingList from './PairingList/PairingList'
import DeviceSessionType from '../../types/DeviceSession';
import Spinner from '../../Components/Spinner';

const ITEMS = [
  {
    code: 'APP_SUPERVISION_LIENS',
    label: 'Supervision Liens'
  }
]


export default function DeviceModaleBody({deviceSessions, reloadDeviceSessions, loading, deleteSession}: {deviceSessions: DeviceSessionType[], reloadDeviceSessions: () => void, loading: boolean, deleteSession: (id: string) => void}) {

  
  const [applications, setApplications] = useState(ITEMS);
  

  return <>
    <div className='grid grid-cols-3 h-[60rem] gap-4'>
      <div className='flex flex-col gap-2'>
        <div className='font-semibold'>
          Nouvelle session
        </div>
        <div className='rounded-md border border-gray-200 p-4'>
          <PairingForm applications={applications} reloadDeviceSessions={reloadDeviceSessions}/>
        </div>
      </div>
      <div className='flex flex-col gap-2 col-span-2'>
        <div className='font-semibold'>
          Sessions actives
        </div>
        <div className='rounded-md border border-gray-200 flex-1'>
          <PairingList deviceSessions={deviceSessions} deleteSession={deleteSession} loading={loading}/>
        </div>
      </div>
    </div>
  </>
}