import React, { useEffect, useState } from 'react';
import PairingFormAppItem from './PairingFormAppItem';
import Button from '../../../Components/Button';
import { useMutation } from '@apollo/client';
import { PAIR_DEVICE } from '../queries';
import toast from 'react-hot-toast';


function PairingForm({applications, reloadDeviceSessions}: {applications: {code: string, label: string}[], reloadDeviceSessions: () => void}) {

  const [pairDevice] = useMutation(PAIR_DEVICE);

  const [selectedApp, setSelectedApp] = useState<string | undefined>();
  const [pairingCode, setPairingCode] = useState('');
  const [sessionName, setSessionName] = useState('');

  const onSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const res = await pairDevice({
        variables: {
          nomSession: sessionName,
          pairingCode: pairingCode,
          deviceSessionViewCode: selectedApp
        }
      })
  
      if(res.data.pairDevice?.success) {
        toast.success('Session appairée avec succès');
      }
      else {
        throw new Error();
      }
  
      setPairingCode('');
      setSessionName('');
      setSelectedApp(applications && applications[0].code);
  
      reloadDeviceSessions();
    }
    catch {
      toast.error('Erreur lors de l\'appairage');
    }
    
  }

  useEffect(() => {
    if (applications.length > 0) {
      setSelectedApp(applications && applications[0].code);
    }
  }, [applications]);

  return (
    <form className='flex flex-col gap-2 h-full max-h-full' onSubmit={onSubmitForm}>
      
      <div className='flex flex-col gap-2'>
        <div>Nom session :</div>
        <input
          id="session-name"
          name="session-name"
          type="text"
          autoComplete="no"
          required={true}
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
          value={sessionName}
          onChange={e => setSessionName(e.target.value)}
        />
      </div>

      <div className='flex flex-col gap-2'>
        <div>Code d&apos;appairage :</div>
        <input
          id="pairing-code"
          name="pairing-code"
          type="text"
          autoComplete="no"
          required={true}
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
          value={pairingCode}
          onChange={e => setPairingCode(e.target.value.toUpperCase())}
        />
      </div>

      <div className='flex flex-col gap-2 flex-1'>
        <div>Application :</div>
        <div className='h-[80%] border border-gray-300 rounded-md divide-y divide-gray-300 overflow-y-auto'>
          {
            applications.map((app, index) => {
              return (
                <PairingFormAppItem key={index} label={app.label} onSelect={() => setSelectedApp(app.code)} selected={selectedApp === app.code} />
              )
            })
          }
        </div>
      </div>
      
      <div className='flex justify-end'>
        <Button type='submit' className='mt-4'>Appairer</Button>
      </div>

    </form>
  )
}

export default PairingForm