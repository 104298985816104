const Constants = Object.freeze({
  NODE_ENV: process.env.REACT_APP_NODE_ENV,

  BUILD_ID: process.env.REACT_APP_BUILD_ID,
  BUILD_NUMBER: process.env.REACT_APP_BUILD_NUMBER,

  API_URL: process.env.REACT_APP_API_URL,
  FILER_URL: process.env.REACT_APP_FILER_URL,
  FILER_URL_UPLOAD_SUPPORT: process.env.REACT_APP_FILER_URL_UPLOAD_SUPPORT,
  SUPPORT_URL: process.env.REACT_APP_SUPPORT_URL,
  GSM_URL: process.env.REACT_APP_GSM_URL,
  SAISIE_CONTRAT: 'SAISIE_CONTRAT',
  SAISIE_SITE: 'SAISIE_SITE',
  ACTIFS: 'ACTIFS',
  INACTIFS: 'INACTIFS',
  WITH_ROLES: 'WITH_ROLES',
  WITHOUT_ROLES: 'WITHOUT_ROLES',

  STATUT_ACTIF: 'ACTIF',
  STATUT_INACTIF: 'INACTIF',
  STATUT_ERREUR: 'ERREUR',
  STATUT_DEGRADE: 'DEGRADE',

  // Clikker
  CLIKKER_HOSTNAME: process.env.REACT_APP_CLIKKER_HOSTNAME,

  // ADMI
  ADMI_HOSTNAME: process.env.REACT_APP_ADMI_HOSTNAME,

  // Sentry
  SENTRY_URL: process.env.REACT_APP_SENTRY_URL,

  CONTRAT_STATE_ACTIVE: "Activé",
  CONTRAT_STATE_VERSION_PRELIMINAIRE: "Version préliminaire",
  CONTRAT_STATE_TACITE: "Tacite",
  CONTRAT_STATE_TERMINE: "Terminé",

  TICKET_STATUT_ID_EN_COURS: 1,
  TICKET_STATUT_ID_EN_ATTENTE_CLIENT: 2,
  TICKET_STATUT_ID_EN_ATTENTE_FOURNISSEUR: 3,
  TICKET_STATUT_ID_CLOTURE_CLIENT: 4,
  TICKET_STATUT_ID_CLOTURE_TECHNIQUE: 5,
  TICKET_STATUT_ID_CLOTURE_ADMINISTRATIVE: 6,
  TICKET_STATUT_ID_NOUVEAU: 7,
  TICKET_STATUT_ID_OBSERVATION: 10,

  ROLE_ACCES_ESPACE_CLIENT: 'ACCES_ESPACE_CLIENT',
  ROLE_ACCES_FACTURATION: 'ACCES_FACTURATION',
  ROLE_ACCES_ANNUAIRE: 'ACCES_ANNUAIRE',
  ROLE_ACCES_CONTRAT: 'ACCES_CONTRAT',
  ROLE_ACCES_PRESTATION: 'ACCES_PRESTATION',
  ROLE_ACCES_SUPERVISION: 'ACCES_SUPERVISION',
  ROLE_ACCES_SUPPORT: 'ACCES_SUPPORT',
  ROLE_ADMIN_ANNUAIRE: 'ADMIN_ANNUAIRE',
  ROLE_ADMIN_CONTRAT: 'ADMIN_CONTRAT',
  ROLE_ADMIN_BLUE_APPS: 'ADMIN_BLUE_APPS',
  ROLE_ADMIN_FACTURATION: 'ADMIN_FACTURATION',
  ROLE_ADMIN_PRESTATION: 'ADMIN_PRESTATION',
  ROLE_ADMIN_SUPPORT: 'ADMIN_SUPPORT',
  ROLE_ADMIN_SUPPORT_V2: 'ADMIN_SUPPORT_V2',
  ROLE_CONTACT_ALERTE_IMPACT: 'CONTACT_ALERTE_IMPACT',

  OTP_MODE_DISABLED: 'DISABLED',
  OTP_MODE_EMAIL: 'EMAIL',
  OTP_MODE_SMS: 'SMS',
  OTP_MODE_APPLICATION: 'APPLICATION',

  PASSWORD_COMPLEXITY_1_REGEX:
    '(((?=.*[a-z])|(?=.*[A-Z])|(?=.*[0-9]))(?=.*[^A-Za-z0-9])(?=.{8,}))',

  PASSWORD_COMPLEXITY_2_REGEX:
    '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})',

  // MOBILE_FRANCAIS_REGEX: /^(\+33 |0)[1-9]( \d\d){4}$/,
  MOBILE_FRANCAIS_REGEX: /^((\+|00)33\s?|0)[67](\s?\d{2}){4}$/,

  GSM_FOURNISSEUR_EIT: 4,
  GSM_FOURNISSEUR_ALPHALINK: 5,

  TYPE_LOGIN_AS_ESPACE_CLIENT: 'LOGIN_AS_ESPACE_CLIENT',

  NB_JOURS_AFFICHAGE_FACTURE_PLUGIN: 60,

  /** Etat des lignes */
  ETAT_LIGNE_SUSPENDUE: "SUSPENDUE",
  ETAT_LIGNE_RESILIEE: "RESILIEE",
  ETAT_LIGNE_EN_SERVICE: "EN SERVICE",

  /** Statut PRESTATION */
  ETAT_PRESTATION_INITIEE: 'INITIEE',
  ETAT_PRESTATION_EN_SERVICE: 'EN SERVICE',
  ETAT_PRESTATION_COMMANDE_EN_COURS: 'COMMANDE EN COURS',
  ETAT_PRESTATION_RESILIEE: 'RESILIEE',
  ETAT_PRESTATION_COMMANDE_REJETEE: 'COMMANDE REJETEE',
  ETAT_PRESTATION_COMMANDE_ANNULEE: 'COMMANDE ANNULEE',

  /** STATUTS CONTRAT */
  STATUT_CONTRAT_TERMINE: "Terminé",

  /** CODES ERREUR API */
  MAIL_EXISTE_DEJA: 'MAIL_EXISTE_DEJA',
  NOM_PRENOM_OBLIGATOIRE: 'NOM_PRENOM_OBLIGATOIRE',

  TYPE_CONTACT: 'CONTACT',
  TYPE_PERSONNE: 'PERSONNE',

  /** FEATURE FLAG */
  FEATURE_FLAG_KPI_CYBER: 'KPI_CYBER',

  /** Ticket Qualificatif */
  TICKET_QUALIF_INCIDENT: "INCIDENT",

  /** Bon de commande founisseur */
  BDC_FOURNISSEUR_ALPHALINK: "ALPHALINK",
  BDC_FOURNISSEUR_EIT: "EIT",
  BDC_FOURNISSEUR_ARROW: "ARROW",

  /** Chart types */
  LINE_CHART: "line",
  BAR_CHART: "bar",
  DOUGHNUT_CHART: "doughnut",
  PIE_CHART: "pie",
  RADAR_CHART: "radar",

  /** Filtres Documentation */
  DOWNLOADED: "DOWNLOADED",
  NOT_DOWNLOADED: "NOT_DOWNLOADED",

  /** Vues documentation */
  VIGNETTES: "Vignettes",
  LISTE: "Liste",

  APPROVAL_DL: "APPROVAL_DL",
  APPROVAL_CONSENT: "APPROVAL_CONSENT",

  // Approbation document
  APPROBATION_NONE: "NONE",
  APPROBATION_READ: "READ",

});

export default Constants;
