import React from 'react'
import Logo from '../../Components/Logo';

import { useP } from '../../services/i18n';

type LoginContainerType = {
  forgottenPassword: () => void,
  submit: () => void
  email: string,
  setEmail: any,
  password: string,
  setPassword: any,
}

function LoginContainer(props: LoginContainerType) {

  const p = useP();

  const onSubmit = (event) => {
    event.preventDefault();
    props.submit()
  }

  return (
    <div className="flex-1 flex flex-col lg:justify-center p-4">
      <div className="mx-auto w-full max-w-sm lg:w-96">
        <div className='hidden lg:block'>
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">{p.t("login.title")}</h2>
          <p className="mt-2 text-sm text-gray-600">
            {p.t("login.subtitle")}
          </p>
        </div>

        <div className="mt-8">
          <div>
            {/*
            <div>
              <p className="text-sm font-medium text-gray-700">{p.t("login.connectWith.title")}</p>

              <div className="mt-1 grid grid-cols-2 gap-3">
                <div
                  onClick={() => alert('Connexion avec Google')}
                  className="cursor-pointer w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Sign in with Google</span>

                  <Google size={20} />
                </div>

                <div
                  onClick={() => alert('Connexion avec Office')}
                  className="cursor-pointer w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Sign in with Google</span>

                  <Microsoft size={20} />
                </div>

              </div>
            </div>
            */}

            <div className="mt-6 relative">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">{p.t("login.connectWith.title")}</span>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <form className="space-y-6" onSubmit={onSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {p.t("login.loginForm.email")}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    value={props.email}
                    onChange={e => props.setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="space-y-1">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  {p.t("login.loginForm.password")}
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    value={props.password}
                    onChange={e => props.setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex items-center justify-end">
                <div className="text-sm">
                  <div onClick={() => props.forgottenPassword()} className="font-medium text-primary-600 hover:text-primary-500 cursor-pointer">
                    {p.t("login.loginForm.forgottenPassword")}
                  </div>
                </div>
              </div>

              <div>
                <button
                  type='submit'
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {p.t("login.loginForm.submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div >
    </div >
  )
}

export default LoginContainer