import React from 'react'
import Logo from '../../Components/Logo'
import { useP } from '../../services/i18n';

function LoginBanner() {

  const p = useP();

  return (
    <div className="pt-4 lg:pt-0 lg:flex-1 flex justify-center bg-radient-ellipse-c from-primary-700 from-0% to-primary-800 to-70%">
      <div className='flex flex-col items-center justify-center flex-1'>
        <div>
          <Logo type="gold" className="w-32 lg:w-52 drop-shadow-2xl hover:animate-pulse" />
          <div className='lg:hidden'>
            <h2 className="mt-6 text-xl lg:text-3xl font-extrabold text-white lg:text-gray-900">{p.t("login.title")}</h2>
            <p className="mt-2 text-sm text-gray-50 lg:text-gray-600">
              {p.t("login.subtitle")}
            </p>
          </div>
        </div>
      </div>
      <div className='flex-1 flex items-end'><img src="/images/gorilleclavier_detoure.webp" className='lg:fixed lg:bottom-0 lg:-right-20 lg:w-[30%]'/></div>
    </div>
  )

}

export default LoginBanner