import React from 'react'
import DeviceSessionType from '../../../types/DeviceSession'
import { TrashIcon } from '@heroicons/react/24/outline'

const PairingListItem = ({deviceSession, deleteSession}: {deviceSession: DeviceSessionType, deleteSession: () => void}) => {

  const onClickDeleteSession = () => {
    /** Confirm oui non suppression session */
    deleteSession();

  }

  return (
    <div className='group flex flex-row'>
      <div className='grid grid-cols-5 p-2'>
        <div>{deviceSession.id}</div>
        <div className='col-span-1'>{deviceSession.nom}</div>
        <div className='col-span-3'>{deviceSession.accessToken.userAgent}</div>
      </div>
      <div className="flex items-center justify-center p-4 bg-white hover:bg-gray-50 cursor-pointer transition duration-300" onClick={onClickDeleteSession}><TrashIcon className='hidden group-hover:block text-red-600 transition duration-300 w-5 h-5'/></div>
    </div>
  )
}

export default PairingListItem