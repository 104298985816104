/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import ModalCommandeArrow from "./ModalCommandeArrow/ModalCommandeArrow";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useP } from "../../services/i18n";
import { useMutation, useQuery } from "@apollo/client";
import { COMMANDER_LIGNE, GET_OPTIONS } from "./queries";
import { toastError, toastSuccess } from "../../Components/Utils/Utils";
import { BonDeCommandeModeleType } from "../../types/BonDeCommandeModeleType";
import * as Sentry from "@sentry/react";
import { AnyObject, Maybe } from "yup/lib/types";
import { RequiredStringSchema } from "yup/lib/string";
import { ModalConfirmation } from "../../Components/ModalDialog/ModalConfirmation";


declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    checkLuhn(): RequiredStringSchema<TType, TContext>;
  }
}

type CommandeArrowType = {
  isOpen: boolean,
  onCloseModalCommandeArrow: () => void,
  abonnementId: number,
  downPossible: boolean,
  bonDeCommandeModele: BonDeCommandeModeleType
}

export default function CommandeArrow({
  isOpen,
  onCloseModalCommandeArrow,
  abonnementId,
  downPossible,
  bonDeCommandeModele
}: CommandeArrowType) {
  const p = useP();
  const [minQuantity, setMinQuantity] = useState(1);
  const [associe, setAssocie] = useState(false);
  const validationSchema = yup.object().shape({
    nouvelleQuantite: yup.number().min(minQuantity,p.t('form.incorrectArrowNumber')).required(p.t('form.required')).typeError(p.t('form.incorrectArrowNumber')),
  });
  
  const { getValues, setValue, register, control, handleSubmit, formState: { errors, isDirty }, reset, watch } = useForm<any>({
    resolver: yupResolver(validationSchema),
    mode: "all"
  });
  const [modalIsOpen, setModalIsOpen] = useState(isOpen)
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)
  

  
  
  const { loading: loadingOptions, data: dataOptions, refetch: getOptions } = useQuery(GET_OPTIONS, {
    variables: {
      abonnementId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });  

  const [commanderLigne, { loading: loadingCommanderLigne }] =
    useMutation(COMMANDER_LIGNE);

  const onSubmit: SubmitHandler<any> = async data => {
    commanderLigne({
      variables: {
        bonCommandeInput: {
          abonnementId: parseInt(abonnementId.toString(), 10),
          values: [{
            key: "nouvelleQuantite",
            value: data.nouvelleQuantite.toString()
          }]
        }
      }
    }).then(({ data }) => {
      if (data.submitCommandeArrow.success) {
        toastSuccess(p.t('commandeArrow.form.successMessage'));
        getOptions();
        setModalIsOpen(false);
      } else {

        const codeErreur = data?.submitCommandeArrow?.codeErreur;
        const tradErreur = `prestations.gsm.errors.${codeErreur}`
        const erreurMessage = p.has(tradErreur) ? p.t(tradErreur) : `${p.t('error.defaultMessage')} (Err: ${codeErreur})`

        console.error(data.submitCommandeArrow.message)
        Sentry.captureException(data.submitCommandeArrow.message);
        toastError(erreurMessage);
      }
    })
      .catch((error) => {
        Sentry.captureException(error);
        toastError(p.t('error.defaultMessage'));
      });
  };
  type ModalData = {
    type: string | null;
    message: string | null;
    okButtonLabel: string | undefined;
    cancelButtonLabel: string | undefined;
  };
  const [confirmModalData, setConfirmModalData] = useState<ModalData>({ type: null, message: null, okButtonLabel: undefined, cancelButtonLabel: undefined })

  const onCloseConfirm = (confirm: boolean) => {
    if (confirmModalData?.type === 'validation') {
      onSubmitForm(confirm)
    } else if (confirmModalData?.type === 'annulation') {
      confirmAction(confirm)
    }
  }
  const nouvelleQuantite = watch('nouvelleQuantite');

  const closeModalCommandeArrow = () => {
    if (!confirmIsOpen) {

      if (isDirty && nouvelleQuantite && parseInt(nouvelleQuantite) !== parseInt(dataOptions?.abonnement?.quantite)) {
        setConfirmModalData({
          type: 'annulation',
          message: p.t('commandeArrow.form.confirmMessage'),
          okButtonLabel: p.t('commandeArrow.form.okButtonLabel'),
          cancelButtonLabel: p.t('commandeArrow.form.cancelButtonLabel')
        })
      } else {
        setModalIsOpen(false)
        onCloseModalCommandeArrow()
      }
    }
    else if (!isDirty) {
      setModalIsOpen(false)
      onCloseModalCommandeArrow()
    }
  }

  const confirmAction = (confirm: boolean) => {
    if (confirm) {
      setModalIsOpen(false)
      onCloseModalCommandeArrow()
    }
    setConfirmIsOpen(false)
  }


  const onSubmitForm = (confirm: boolean) => {
    if (confirm) {
      handleSubmit(onSubmit)()
    }
    setConfirmIsOpen(false)


  }
  const onConfirmSubmitForm = () => {
    if (!confirmIsOpen) {
      setConfirmModalData({
        type: 'validation',
        message: p.t('commandeArrow.form.confirmMessageValidation'),
        okButtonLabel: p.t('commandeArrow.form.okButtonLabel'),
        cancelButtonLabel: p.t('commandeArrow.form.cancelButtonLabel')
      })
    }

  }
  useEffect(() => {
    if (confirmModalData?.type) {
      setConfirmIsOpen(true)
    }
  }, [confirmModalData])
  useEffect(() => {
    if (dataOptions?.abonnement && !loadingOptions) {
      if(!dataOptions?.abonnement?.abonnementDeploiement[0]?.id){
        toastError(p.t('commandeArrow.form.linkError'));
        onCloseModalCommandeArrow();
      }else{
        setAssocie(true);
      }
      if(!downPossible){
            const newMinQuantity = dataOptions?.abonnement?.quantite || 1;
            setMinQuantity(newMinQuantity);
            // Redéfinir le schéma de validation avec la nouvelle valeur minimale
            validationSchema.fields.nouvelleQuantite = yup.number().min(newMinQuantity, p.t('form.incorrectArrowNumber')).required(p.t('form.required')).typeError(p.t('form.incorrectArrowNumber'));
            reset(getValues());
      }
    }
  }, [dataOptions])
  useEffect(() => {
    isOpen ? setModalIsOpen(true) : setModalIsOpen(false)
    if(!modalIsOpen && isOpen){
      reset();
      getOptions();
    }
    if (isOpen && associe && !loadingOptions) {
      reset()
    }

  }, [isOpen,associe, loadingOptions])

  return <>
    <ModalCommandeArrow
      loading={loadingCommanderLigne || loadingOptions}
      isOpen={modalIsOpen}
      onClose={() => closeModalCommandeArrow()}
      register={register}
      control={control}
      getValues={getValues}
      setValue={setValue}
      errors={errors}
      saveForm={() => onConfirmSubmitForm()}
      abonnement={dataOptions?.abonnement}
      watch={watch}
    />
    {confirmIsOpen && confirmModalData?.type &&
      <ModalConfirmation
        isOpen={confirmIsOpen}
        onClose={(confirm) => onCloseConfirm(confirm)}
        message={confirmModalData.message}
        okButtonLabel={confirmModalData?.okButtonLabel}
        cancelButtonLabel={confirmModalData?.cancelButtonLabel}
      />
    }

  </>
}