import React, { useContext, useEffect, useState } from "react";
import "./Login.css";
import { toastError, toastSuccess } from "../Components/Utils/Utils";
import LoginBanner from "./LoginBanner/LoginBanner";
import LoginContainer from "./LoginContainer/LoginContainer";
import { CHECK_AUTH, DATABASE_AUTH, SEND_RENEW_PASSWORD_REQUEST } from "./queries";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { authContext } from "../AuthContext";
import queryString from 'query-string'
import { ClientJS } from 'clientjs';
import MfaModale from "./MfaModale/MfaModale";
import { useP } from "../services/i18n";
import ChangePasswordModale from "./ChangePasswordModale/ChangePasswordModale";
import Constants from "../commons/Constants";

const { TYPE_LOGIN_AS_ESPACE_CLIENT, OTP_MODE_DISABLED, OTP_MODE_SMS, OTP_MODE_APPLICATION, OTP_MODE_EMAIL } = Constants;
const clientjs = new ClientJS();

function Login() {

  const p = useP();

  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otpMode, setOtpMode] = useState<string>(OTP_MODE_EMAIL);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');


  const params = queryString.parse(location.search);

  const [checkAuth] = useMutation(CHECK_AUTH);
  const [sendDatabaseAuth, { loading: loadingSendDatabaseAuth }] = useMutation(DATABASE_AUTH);
  const [sendChangePassword, { loading: loadingSendChangePassword }] = useMutation(SEND_RENEW_PASSWORD_REQUEST);

  const navigate = useNavigate();

  const { setLoggedInUser } = useContext(authContext);

  const processAuthResult = (result = { success: false, otp: 'ERROR', otpMode: 'ERROR' }) => {

    const { success, otp, otpMode } = result;

    if (!success) return

    switch (otpMode) {
      case OTP_MODE_DISABLED:
        submitLogin(otp);
        break;

      case OTP_MODE_EMAIL:
        setOtpMode(OTP_MODE_EMAIL);
        toastSuccess(p.t('login.toasts.checkAuth.sendedByEmail'));
        setOtpModalOpen(true);
        break;

      case OTP_MODE_APPLICATION:
        setOtpMode(OTP_MODE_APPLICATION);
        setOtpModalOpen(true);
        break;

      case OTP_MODE_SMS:
        setOtpModalOpen(true);
        setOtpMode(OTP_MODE_SMS);
        break;

      default:
        break;
    }


  }

  const submitAuth = () => {

    checkAuth({
      variables: {
        authentication: {
          email,
          password,
          type: "AD"
        },
      },
    })
      .then(({ data }) => {

        const { authent } = data;

        if (authent.success) {

          processAuthResult(authent);
        } else {
          toastError(p.t('login.toasts.checkAuth.incorrect'));
        }
      })
      .catch(() => {
        toastError(p.t('login.toasts.checkAuth.error'));
      });
  }

  const submitLogin = (otp: string) => {

    const fingerprint = clientjs.getFingerprint();
    const userAgent = clientjs.getUserAgent();
    const mobileDevice = clientjs.isMobile();

    sendDatabaseAuth({
      variables: {
        authentication: {
          email,
          password,
          otp,
          type: "AD",
          fingerprint: fingerprint.toString(),
          userAgent,
          mobileDevice
        },
      },
    })
      .then(({ data }) => {

        const { login } = data;

        if (login.success) {

          setLoggedInUser(login);
          const redir = params?.redir?.toString();
          if (redir != null) {
            window.location.href = decodeURIComponent(redir);
          }
          else {
            navigate('/')
          }

        } else {
          toastError(p.t('login.toasts.checkOtp.incorrect'));
        }
      })
      .catch(() => {
        toastError(p.t('login.toasts.checkOtp.error'));
      });
  };

  const submitChangePassword = (email: string) => {

    sendChangePassword({
      variables: {
        email
      },
    })
      .then(({ data }) => {

        const { sendRenewPasswordRequest } = data;

        setChangePasswordModalOpen(false)

        if (sendRenewPasswordRequest.success) {
          toastSuccess(p.t('login.toasts.changePassword.success'));
        } else {
          toastError(p.t('login.toasts.changePassword.error'));
        }
      })
      .catch(() => {
        toastError(p.t('login.toasts.changePassword.error'));
      });
  }

  const forgottenPassword = () => {
    setChangePasswordModalOpen(true)
  };

  const connectAs = () => {
    sendDatabaseAuth({
      variables: {
        authentication: {
          loginAs: params?.key,
          type: TYPE_LOGIN_AS_ESPACE_CLIENT,
        },
      },
    })
      .then(({ data }) => {

        const { login } = data;

        if (login.success) {
          setLoggedInUser(login);

          if (params.redir != null) {
            window.location.href = decodeURIComponent(`${params.redir}`);
          }
          else {
            navigate('/')
          }
        } else {
          toastError(p.t('login.toasts.connectAs.incorrect'));
        }
      })
      .catch(() => {
        toastError(p.t('login.toasts.connectAs.incorrect'));
      });
  }

  useEffect(() => {
    if (params?.expired) {
      toastError(p.t('login.toasts.expired'))
    }

    if (params?.key) {
      connectAs();
    }
  }, [])

  return (
    <>
      <div className="min-h-full flex flex-col-reverse lg:flex-row bg-white">        
        <LoginContainer forgottenPassword={forgottenPassword} submit={submitAuth} email={email} setEmail={setEmail} password={password} setPassword={setPassword} />
        <LoginBanner />
        {otpModalOpen && <MfaModale open={otpModalOpen} setOpen={setOtpModalOpen} submit={submitLogin} reset={submitAuth} loading={loadingSendDatabaseAuth} otpMode={otpMode} />}
        {changePasswordModalOpen && <ChangePasswordModale open={changePasswordModalOpen} setOpen={setChangePasswordModalOpen} submit={submitChangePassword} loading={loadingSendChangePassword} />}
      </div>
    </>
  );
}

export default Login;
